import Link from "next/link";
import { useRouter } from "next/router";

const langMap: { [locale: string]: string } = {
  fr: "FR",
  en: "EN",
};

type Props = {
  className?: string;
  overridePath?: string;
};

export default function LocaleSelector({ className, overridePath }: Props) {
  const { asPath, locale: currentLocale, locales } = useRouter();
  const otherLocales =
    locales?.filter((locale) => locale !== currentLocale) || [];

  const href = (() => {
    if (overridePath && otherLocales.length > 0) {
      if (otherLocales[0] === "en") {
        return `/${overridePath}`;
      }

      return `/fr/${overridePath}`;
    }

    return asPath;
  })();
  return (
    <>
      {otherLocales.map((locale) => (
        <Link key={locale} href={href} locale={locale}>
          <a className={className}>{langMap[locale]}</a>
        </Link>
      ))}
    </>
  );
}
