type Props = React.SVGProps<SVGSVGElement> & {
  color?: string;
  locale?: string;
};

//

export default function Signature({ color, locale = "en", ...props }: Props) {
  if (locale === "en") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 108.81 1427.73 406.69"
        {...props}
      >
        <path
          d="M0 256.379C0 304.794 35.8977 342.93 83.8732 342.93C109.706 342.93 129.5 332.318 134.197 318.059V339.945H184.186V172.812H134.197V194.699C129.5 180.108 109.706 169.828 83.8732 169.828C35.8977 169.828 0 208.295 0 256.379ZM49.9884 256.379C49.9884 232.834 68.776 214.596 92.9315 214.596C117.423 214.596 137.217 232.834 137.217 256.379C137.217 279.923 117.423 298.162 92.9315 298.162C68.776 298.162 49.9884 279.923 49.9884 256.379Z"
          fill={color}
        />
        <path
          d="M214.254 403.947H264.242V318.059C268.939 332.65 288.733 342.93 314.566 342.93C362.542 342.93 398.439 304.463 398.439 256.379C398.439 207.963 362.542 169.828 314.566 169.828C288.733 169.828 268.939 180.44 264.242 194.699V172.812H214.254V403.947ZM261.223 256.379C261.223 232.834 281.017 214.596 305.508 214.596C329.663 214.596 348.451 232.834 348.451 256.379C348.451 279.923 329.663 298.162 305.508 298.162C281.017 298.162 261.223 279.923 261.223 256.379Z"
          fill={color}
        />
        <path
          d="M417.355 403.947H467.344V318.059C472.041 332.65 491.835 342.93 517.668 342.93C565.643 342.93 601.541 304.463 601.541 256.379C601.541 207.963 565.643 169.828 517.668 169.828C491.835 169.828 472.041 180.44 467.344 194.699V172.812H417.355V403.947ZM464.324 256.379C464.324 232.834 484.119 214.596 508.61 214.596C532.765 214.596 551.553 232.834 551.553 256.379C551.553 279.923 532.765 298.162 508.61 298.162C484.119 298.162 464.324 279.923 464.324 256.379Z"
          fill={color}
        />
        <path
          d="M623.268 339.943H673.256V258.366C673.256 231.174 690.031 214.924 718.548 214.924C724.922 214.924 730.625 215.588 735.322 216.583V170.157C732.638 169.825 726.935 169.162 722.238 169.162C699.089 169.162 680.972 178.779 673.256 195.691V172.81H623.268V339.943Z"
          fill={color}
        />
        <path
          d="M738.67 257.374C738.67 309.769 776.245 343.925 831.266 343.925C857.099 343.925 881.59 336.298 904.068 313.417L874.88 285.893C863.138 296.836 851.06 302.142 833.614 302.142C807.11 302.142 789.665 289.209 788.658 269.312H916.481C917.152 261.685 917.152 253.395 917.152 253.395C917.152 206.306 882.596 168.502 830.259 168.502C777.587 168.502 738.67 202.658 738.67 256.048V257.374ZM789.329 237.146C790.671 221.56 808.452 209.29 830.259 209.29C850.389 209.29 866.828 221.891 866.828 237.146H789.329Z"
          fill={color}
        />
        <path
          d="M936.955 339.943H986.943V246.096C986.943 226.531 1001.03 212.935 1020.16 212.935C1040.29 212.935 1051.02 225.204 1051.02 244.438V339.943H1101.01V238.469C1101.01 195.359 1077.86 169.162 1036.26 169.162C1012.78 169.162 993.989 179.11 986.943 193.701V172.81H936.955V339.943Z"
          fill={color}
        />
        <path
          d="M1140.81 339.944H1189.46V217.579H1226.7V172.811H1189.46V108.81H1140.81V172.811H1118.33V217.579H1140.81V339.944Z"
          fill={color}
        />
        <path
          d="M1283.53 167.323L1251.76 198.731L1395.95 341.261L1427.73 309.853L1283.53 167.323Z"
          fill="#FF9F5A"
        />
        <path
          d="M1395.94 169.709L1425.68 199.112L1340.44 283.368L1310.69 253.965L1395.94 169.709Z"
          fill="#FF9F5A"
        />
        <path
          d="M1281.02 282.285C1296.87 282.285 1309.7 294.979 1309.7 310.634C1309.7 326.295 1296.87 338.988 1281.02 338.988C1265.17 338.988 1252.34 326.295 1252.34 310.634C1252.34 294.979 1265.17 282.285 1281.02 282.285Z"
          fill="#FF9F5A"
        />
        <path
          d="M1383.13 470.074C1392.82 470.074 1400.66 462.855 1400.66 453.534C1400.66 444.214 1392.82 436.986 1383.13 436.986C1373.56 436.986 1366.13 444.214 1366.13 453.534C1366.13 462.855 1373.56 470.074 1383.13 470.074ZM1379.53 419.26C1389.77 419.26 1397.6 423.336 1399.47 429.115V420.446H1419.26V486.622H1399.47V477.954C1397.6 483.597 1389.77 487.8 1379.53 487.8C1360.55 487.8 1346.32 472.701 1346.32 453.534C1346.32 434.495 1360.55 419.26 1379.53 419.26Z"
          fill={color}
        />
        <path
          d="M1061.81 469.811C1071.51 469.811 1079.34 462.719 1079.34 453.534C1079.34 444.205 1071.51 436.986 1061.81 436.986C1052.24 436.986 1044.81 444.205 1044.81 453.534C1044.81 462.719 1052.24 469.811 1061.81 469.811ZM1058.22 419.26C1068.46 419.26 1076.29 423.336 1078.15 429.106V420.446H1097.94V475.59C1097.94 489.249 1096.08 500.273 1086.25 507.89C1080.14 512.618 1071.37 515.499 1061.94 515.499C1049.06 515.499 1038.3 512.618 1029.13 506.178L1038.56 492.003C1044.68 495.807 1051.31 498.307 1059.15 498.307C1064.6 498.307 1069.52 497.12 1072.84 494.892C1078.02 491.342 1078.68 485.436 1078.68 480.445V479.005C1075.9 483.597 1068.19 487.012 1058.22 487.012C1039.23 487.012 1025.01 472.302 1025.01 453.534C1025.01 434.495 1039.23 419.26 1058.22 419.26Z"
          fill={color}
        />
        <path
          d="M1299.93 470.072C1309.63 470.072 1317.46 462.853 1317.46 453.533C1317.46 444.212 1309.63 436.984 1299.93 436.984C1290.37 436.984 1282.93 444.212 1282.93 453.533C1282.93 462.853 1290.37 470.072 1299.93 470.072ZM1296.34 419.258C1306.58 419.258 1314.41 423.334 1316.27 429.113V395.101H1336.07V486.621H1316.27V477.952C1314.41 483.595 1306.58 487.798 1296.34 487.798C1277.35 487.798 1263.13 472.699 1263.13 453.533C1263.13 434.493 1277.35 419.258 1296.34 419.258Z"
          fill={color}
        />
        <path
          d="M1188.46 420.446H1208.25V428.716C1211.03 422.937 1218.48 418.997 1227.78 418.997C1244.24 418.997 1253.42 429.368 1253.42 446.442V486.622H1233.62V448.806C1233.62 441.188 1229.37 436.333 1221.4 436.333C1213.83 436.333 1208.25 441.714 1208.25 449.458V486.622H1188.46V420.446Z"
          fill={color}
        />
        <path
          d="M1158.69 445.913C1158.69 439.871 1152.18 434.88 1144.21 434.88C1135.58 434.88 1128.53 439.744 1128 445.913H1158.69ZM1107.94 453.395C1107.94 432.254 1123.35 418.74 1144.21 418.74C1164.93 418.74 1178.61 433.703 1178.61 452.344C1178.61 452.344 1178.61 455.632 1178.35 458.648H1127.74C1128.13 466.528 1135.04 471.646 1145.54 471.646C1152.45 471.646 1157.22 469.545 1161.88 465.215L1173.43 476.111C1164.53 485.169 1154.84 488.185 1144.6 488.185C1122.82 488.185 1107.94 474.662 1107.94 453.92V453.395Z"
          fill={color}
        />
        <path
          d="M978.618 470.072C988.313 470.072 996.149 462.853 996.149 453.533C996.149 444.212 988.313 436.984 978.618 436.984C969.051 436.984 961.619 444.212 961.619 453.533C961.619 462.853 969.051 470.072 978.618 470.072ZM975.026 419.258C985.262 419.258 993.097 423.334 994.957 429.113V395.101H1014.75V486.621H994.957V477.952C993.097 483.595 985.262 487.798 975.026 487.798C956.039 487.798 941.816 472.699 941.816 453.533C941.816 434.493 956.039 419.258 975.026 419.258Z"
          fill={color}
        />
        <path
          d="M870.486 395.101H933.716V413.479H890.94V430.68H930.931V448.669H890.94V468.233H934.642V486.621H870.486V395.101Z"
          fill={color}
        />
        <path
          d="M714.434 480.323C728.279 480.323 738.525 468.974 738.525 453.885C738.525 438.795 728.279 427.446 714.434 427.446C700.066 427.446 689.416 438.795 689.416 453.885C689.416 468.974 700.066 480.323 714.434 480.323ZM680.768 394.457H690.61V430.246C693.942 424.507 701.26 418.225 714.434 418.225C733.2 418.225 748.377 433.314 748.377 453.885C748.377 474.446 733.2 489.536 714.434 489.536C701.26 489.536 693.942 483.262 690.61 477.514V487.537H680.768V394.457Z"
          fill={color}
        />
        <path
          d="M756.914 420.233H766.894L788.855 476.049L812.009 420.233H822.127L782.731 513.312H772.88L783.668 487.933L756.914 420.233Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 103.65 1431.43 406.69"
      {...props}
    >
      <path
        d="M0 251.208C0 299.623 35.9918 337.759 84.0931 337.759C109.994 337.759 129.84 327.147 134.549 312.888V334.774H184.668V167.641H134.549V189.527C129.84 174.937 109.994 164.656 84.0931 164.656C35.9918 164.656 0 203.124 0 251.208ZM50.1195 251.208C50.1195 227.663 68.9563 209.424 93.1752 209.424C117.73 209.424 137.576 227.663 137.576 251.208C137.576 274.752 117.73 292.991 93.1752 292.991C68.9563 292.991 50.1195 274.752 50.1195 251.208Z"
        fill={color}
      />
      <path
        d="M214.859 398.775H264.979V312.888C269.688 327.479 289.534 337.759 315.435 337.759C363.536 337.759 399.528 299.291 399.528 251.208C399.528 202.792 363.536 164.656 315.435 164.656C289.534 164.656 269.688 175.268 264.979 189.527V167.641H214.859V398.775ZM261.951 251.208C261.951 227.663 281.797 209.424 306.353 209.424C330.571 209.424 349.408 227.663 349.408 251.208C349.408 274.752 330.571 292.991 306.353 292.991C281.797 292.991 261.951 274.752 261.951 251.208Z"
        fill={color}
      />
      <path
        d="M418.449 398.775H468.569V312.888C473.278 327.479 493.124 337.759 519.025 337.759C567.126 337.759 603.118 299.291 603.118 251.208C603.118 202.792 567.126 164.656 519.025 164.656C493.124 164.656 473.278 175.268 468.569 189.527V167.641H418.449V398.775ZM465.541 251.208C465.541 227.663 485.387 209.424 509.943 209.424C534.161 209.424 552.998 227.663 552.998 251.208C552.998 274.752 534.161 292.991 509.943 292.991C485.387 292.991 465.541 274.752 465.541 251.208Z"
        fill={color}
      />
      <path
        d="M624.9 334.766H675.02V253.189C675.02 225.997 691.838 209.747 720.43 209.747C726.821 209.747 732.54 210.411 737.249 211.406V164.98C734.558 164.648 728.84 163.985 724.13 163.985C700.921 163.985 682.756 173.602 675.02 190.514V167.633H624.9V334.766Z"
        fill={color}
      />
      <path
        d="M740.652 252.231C740.652 304.626 778.326 338.782 833.491 338.782C859.392 338.782 883.947 331.155 906.484 308.274L877.22 280.75C865.447 291.693 853.337 296.999 835.846 296.999C809.272 296.999 791.781 284.066 790.772 264.169H918.93C919.603 256.542 919.602 248.252 919.602 248.252C919.602 201.163 884.956 163.359 832.482 163.359C779.672 163.359 740.652 197.515 740.652 250.905V252.231ZM791.445 232.003C792.79 216.417 810.618 204.147 832.482 204.147C852.664 204.147 869.147 216.748 869.147 232.003H791.445Z"
        fill={color}
      />
      <path
        d="M939.408 334.766H989.528V240.919C989.528 221.354 1003.66 207.758 1022.83 207.758C1043.01 207.758 1053.77 220.027 1053.77 239.261V334.766H1103.89V233.292C1103.89 190.182 1080.68 163.985 1038.97 163.985C1015.43 163.985 996.591 173.933 989.528 188.524V167.633H939.408V334.766Z"
        fill={color}
      />
      <path
        d="M1143.71 334.784H1192.48V212.419H1229.82V167.651H1192.48V103.65H1143.71V167.651H1121.17V212.419H1143.71V334.784Z"
        fill={color}
      />
      <path
        d="M1284.29 277.136C1300.18 277.136 1313.05 289.829 1313.05 305.485C1313.05 321.146 1300.18 333.839 1284.29 333.839C1268.4 333.839 1255.53 321.146 1255.53 305.485C1255.53 289.829 1268.4 277.136 1284.29 277.136Z"
        fill="#FF9F5A"
      />
      <path
        d="M1399.6 164.525L1429.42 193.928L1343.96 278.185L1314.13 248.782L1399.6 164.525Z"
        fill="#FF9F5A"
      />
      <path
        d="M1286.85 162.163L1254.99 193.571L1399.57 336.101L1431.43 304.693L1286.85 162.163Z"
        fill="#FF9F5A"
      />
      <path
        d="M658.194 474.824C672.105 474.824 682.4 463.619 682.4 448.722C682.4 433.825 672.105 422.621 658.194 422.621C643.756 422.621 633.055 433.825 633.055 448.722C633.055 463.619 643.756 474.824 658.194 474.824ZM624.365 415.5H634.255V425.386C637.603 419.72 644.956 413.517 658.194 413.517C677.05 413.517 692.299 428.414 692.299 448.722C692.299 469.021 677.05 483.918 658.194 483.918C644.956 483.918 637.603 477.725 634.255 472.05V507.391H624.365V415.5Z"
        fill={color}
      />
      <path
        d="M733.609 474.824C748.046 474.824 758.747 463.619 758.747 448.722C758.747 433.825 748.046 422.621 733.609 422.621C719.698 422.621 709.402 433.825 709.402 448.722C709.402 463.619 719.698 474.824 733.609 474.824ZM733.609 413.517C746.847 413.517 754.199 419.72 757.548 425.386V415.5H767.437V481.945H757.548V472.05C754.199 477.725 746.847 483.918 733.609 483.918C714.753 483.918 699.504 469.021 699.504 448.722C699.504 428.414 714.753 413.517 733.609 413.517Z"
        fill={color}
      />
      <path
        d="M788.418 415.513H798.316V426.717C801.665 419.596 808.879 414.458 819.045 414.458C820.512 414.458 822.246 414.585 823.187 414.721V425.262C821.582 424.871 819.313 424.607 816.499 424.607C806.075 424.607 798.316 432.119 798.316 443.333V481.958H788.418V415.513Z"
        fill={color}
      />
      <path
        d="M1386.89 464.913C1396.61 464.913 1404.46 457.694 1404.46 448.374C1404.46 439.053 1396.61 431.825 1386.89 431.825C1377.29 431.825 1369.84 439.053 1369.84 448.374C1369.84 457.694 1377.29 464.913 1386.89 464.913ZM1383.28 414.099C1393.55 414.099 1401.4 418.175 1403.27 423.954V415.286H1423.11V481.462H1403.27V472.794C1401.4 478.437 1393.55 482.64 1383.28 482.64C1364.25 482.64 1349.99 467.54 1349.99 448.374C1349.99 429.334 1364.25 414.099 1383.28 414.099Z"
        fill={color}
      />
      <path
        d="M1064.69 464.651C1074.41 464.651 1082.26 457.558 1082.26 448.374C1082.26 439.044 1074.41 431.825 1064.69 431.825C1055.09 431.825 1047.64 439.044 1047.64 448.374C1047.64 457.558 1055.09 464.651 1064.69 464.651ZM1061.08 414.099C1071.35 414.099 1079.2 418.175 1081.07 423.945V415.286H1100.91V470.43C1100.91 484.089 1099.05 495.112 1089.19 502.73C1083.06 507.458 1074.27 510.338 1064.81 510.338C1051.9 510.338 1041.11 507.458 1031.92 501.018L1041.38 486.842C1047.5 490.647 1054.16 493.147 1062.02 493.147C1067.48 493.147 1072.41 491.96 1075.74 489.732C1080.94 486.181 1081.6 480.275 1081.6 475.285V473.844C1078.81 478.437 1071.08 481.852 1061.08 481.852C1042.05 481.852 1027.79 467.142 1027.79 448.374C1027.79 429.334 1042.05 414.099 1061.08 414.099Z"
        fill={color}
      />
      <path
        d="M1303.34 464.901C1313.06 464.901 1320.92 457.682 1320.92 448.361C1320.92 439.041 1313.06 431.813 1303.34 431.813C1293.75 431.813 1286.3 439.041 1286.3 448.361C1286.3 457.682 1293.75 464.901 1303.34 464.901ZM1299.74 414.087C1310 414.087 1317.86 418.163 1319.72 423.942V389.93H1339.57V481.45H1319.72V472.781C1317.86 478.424 1310 482.627 1299.74 482.627C1280.7 482.627 1266.44 467.528 1266.44 448.361C1266.44 429.322 1280.7 414.087 1299.74 414.087Z"
        fill={color}
      />
      <path
        d="M1191.66 415.281H1211.51V423.55C1214.3 417.771 1221.76 413.831 1231.08 413.831C1247.6 413.831 1256.79 424.202 1256.79 441.276V481.457H1236.95V443.641C1236.95 436.023 1232.68 431.168 1224.69 431.168C1217.1 431.168 1211.51 436.548 1211.51 444.293V481.457H1191.66V415.281Z"
        fill={color}
      />
      <path
        d="M1161.86 440.736C1161.86 434.694 1155.34 429.704 1147.34 429.704C1138.69 429.704 1131.62 434.568 1131.09 440.736H1161.86ZM1110.98 448.218C1110.98 427.077 1126.43 413.562 1147.34 413.562C1168.12 413.562 1181.83 428.526 1181.83 447.167C1181.83 447.167 1181.83 450.455 1181.58 453.471H1130.83C1131.23 461.351 1138.15 466.469 1148.67 466.469C1155.6 466.469 1160.39 464.368 1165.06 460.038L1176.64 470.935C1167.72 479.992 1158 483.009 1147.74 483.009C1125.9 483.009 1110.98 469.485 1110.98 448.743V448.218Z"
        fill={color}
      />
      <path
        d="M981.318 464.901C991.039 464.901 998.894 457.682 998.894 448.361C998.894 439.041 991.039 431.813 981.318 431.813C971.726 431.813 964.275 439.041 964.275 448.361C964.275 457.682 971.726 464.901 981.318 464.901ZM977.716 414.087C987.979 414.087 995.835 418.163 997.7 423.942V389.93H1017.55V481.45H997.7V472.781C995.835 478.424 987.979 482.627 977.716 482.627C958.679 482.627 944.42 467.528 944.42 448.361C944.42 429.322 958.679 414.087 977.716 414.087Z"
        fill={color}
      />
      <path
        d="M872.859 389.93H936.255V408.308H893.366V425.509H933.462V443.497H893.366V463.062H937.183V481.45H872.859V389.93Z"
        fill={color}
      />
    </svg>
  );
}
