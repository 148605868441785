import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import React, { useState } from "react";

import { FooterColumnData } from "./footer";
import { useTranslation } from "react-i18next";

type Props = {
  column: FooterColumnData;
};

export default function FooterMobileColumn({ column }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div
      role="button"
      className="w-full"
      key={column.title}
      onClick={() => setOpen((prev) => !prev)}
    >
      <div className="flex w-full flex-row items-center pt-4">
        <h3 className="font-bold">{column.title}</h3>
        <div className="ml-auto flex h-3 w-6 items-center">
          {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>

      {open && (
        <div className="ml-6 mb-5 flex flex-col gap-y-4 pt-4">
          {column.rootUrl && (
            <Link href={column.rootUrl}>
              <a className="text-sm font-bold text-[#4896E5] hover:underline">
                {t("visit_website")}
              </a>
            </Link>
          )}
          {column.items.map((item) => (
            <Link
              className="text-sm text-black hover:text-black hover:underline"
              key={item.title}
              href={item.href}
            >
              <a className="text-blue text-sm hover:underline">{item.title}</a>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
