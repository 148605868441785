import Link from "next/link";
import React from "react";

import { FooterColumnData } from "./footer";
import { useTranslation } from "react-i18next";

type Props = {
  column: FooterColumnData;
};

export default function FooterColumn({ column }: Props) {
  const { t } = useTranslation();

  return (
    <div className="text-r-sm flex flex-col gap-3" style={{ width: "200px" }}>
      <h3 className="font-bold">{column.title}</h3>
      {column.rootUrl && (
        <Link href={column.rootUrl}>
          <a className="text-sm font-bold text-[#4896E5] hover:underline">
            {t("visit_website")}
          </a>
        </Link>
      )}
      {column.items.map((item) => (
        <Link key={item.title} href={item.href}>
          <a className="text-sm text-black hover:text-black hover:underline">
            {item.title}
          </a>
        </Link>
      ))}
    </div>
  );
}
