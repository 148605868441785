import { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { cx } from "classix";
import { useTranslation } from "react-i18next";

import Signature from "./svgs/signature";
import MenuIcon from "./svgs/menu";
import CloseIcon from "./svgs/close";
import LocaleSelector from "./localeSelector";
import { getLinks } from "utils/links";

type Props = {
  color: "light" | "dark";
  sticky?: boolean;
  overridePath?: string;
};

export default function Header({
  color = "dark",
  sticky = false,
  overridePath,
}: Props) {
  const router = useRouter();
  const { t } = useTranslation();

  const links = getLinks();

  const [isOpened, setIsOpened] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isOpaque = isScrolled || isOpened || sticky;

  return (
    <header
      className={cx(
        `top-0 z-40 w-full transition-colors`,
        sticky ? "sticky" : "fixed",
        color === "dark" ? "text-secondary" : "text-white",
        isOpaque && color === "dark" && "bg-white",
        isOpaque && color === "light" && "bg-secondary"
      )}
    >
      <div
        className={cx(
          `border-b`,
          !isOpaque && "border-transparent",
          isOpaque && color === "dark" && "border-gray-200",
          isOpaque && color === "light" && "border-gray-700"
        )}
      >
        <div className="mx-auto flex max-w-7xl items-center justify-between px-6 py-4">
          <Link href="/">
            <a aria-label={t("home")}>
              <Signature
                color={color === "dark" ? "#282828" : "#ffffff"}
                height={32}
                locale={router.locale}
              />
            </a>
          </Link>
          <div className="lg:hidden">
            <button
              className="p-2"
              onClick={() => setIsOpened(!isOpened)}
              aria-label="Menu"
            >
              {isOpened ? <CloseIcon width={24} /> : <MenuIcon width={24} />}
            </button>
          </div>
          <div className="hidden flex-1 items-center justify-end space-x-12 lg:flex">
            <nav>
              <ul className="flex space-x-12 font-bold">
                {links.map((link) => (
                  <li key={link.href}>
                    <Link href={link.href}>
                      <a
                        className={cx(
                          "py-1 hover:text-primary",
                          `/${router.pathname.split("/")[1]}` == link.href &&
                            "border-b-2 border-primary text-primary"
                        )}
                      >
                        {t(link.name)}
                      </a>
                    </Link>
                  </li>
                ))}
                <li>
                  <LocaleSelector overridePath={overridePath} />
                </li>
              </ul>
            </nav>
            <a
              className="btn-primary !px-8 !py-2"
              href="https://meetings.hubspot.com/info3139"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("demo")}
            </a>
          </div>
        </div>
      </div>
      <div className="relative lg:hidden">
        {isOpened && <Menu color={color} overridePath={overridePath} />}
      </div>
    </header>
  );
}

type MenuProps = {
  color: "light" | "dark";
  overridePath?: string;
};

function Menu({ color, overridePath }: MenuProps) {
  const router = useRouter();
  const { t } = useTranslation();

  const links = getLinks();

  return (
    <div
      className={cx(
        "absolute inset-x-0 top-[-2px] px-4 pb-4 shadow-lg",
        color === "dark" && "bg-white",
        color === "light" && "bg-secondary"
      )}
    >
      <nav>
        <ul>
          {links.map((link) => (
            <li key={link.href}>
              <Link href={link.href}>
                <a className="flex justify-center p-4 font-bold hover:text-primary">
                  <span
                    className={cx(
                      "py-1",
                      `/${router.pathname.split("/")[1]}` == link.href &&
                        "border-b-2 border-primary text-primary"
                    )}
                  >
                    {t(link.name)}
                  </span>
                </a>
              </Link>
            </li>
          ))}
          <li>
            <LocaleSelector
              className={"flex justify-center p-4 font-bold hover:text-primary"}
              overridePath={overridePath}
            />
          </li>
        </ul>
      </nav>
      <div className="m-4 flex justify-center">
        <a
          className="btn-primary"
          href="https://meetings.hubspot.com/info3139"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t("demo")}
        </a>
      </div>
    </div>
  );
}
