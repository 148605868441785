import Footer from "./footer";
import Header from "./header";

type Props = {
  color?: "light" | "dark";
  stickyHeader?: boolean;
  children: React.ReactNode;
  overridePath?: string;
};

export default function Layout({
  children,
  color = "dark",
  overridePath,
  stickyHeader,
}: Props) {
  return (
    <div className="flex min-h-screen flex-col">
      <Header color={color} overridePath={overridePath} sticky={stickyHeader} />
      <main className="overflow-x-clip">{children}</main>
      <Footer overridePath={overridePath} />
    </div>
  );
}
